import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import { pipe } from 'fp-ts/function';

import { PaymentIntent, PaymentIntentParams, RedirectError } from './model';
import fetchUrl, { FetchError } from '../../utils/fetch';

export namespace PaymentService {
  const URI = '/cart/payment';

  export function getPaymentIntent(
    csrfToken: string | undefined,
    params: PaymentIntentParams,
  ): TE.TaskEither<Error, PaymentIntent> {
    const headers = {
      'Content-Type': 'application/json',
      'Csrf-Token': csrfToken,
    };

    const body = JSON.stringify(params);

    const fetchPayment = () => fetchUrl(URI, { method: 'POST', headers, body });

    return pipe(
      TE.tryCatch(fetchPayment, failed => failed as Error),
      TE.orLeft(failed =>
        failed instanceof FetchError
          ? pipe(
              () => failed.res.json(),
              T.map(({ error }) => new Error(error)),
            )
          : T.of(failed),
      ),
      TE.filterOrElseW(
        ({ redirected }) => !redirected,
        res => new RedirectError(res.url),
      ),
      TE.flatMapTask(res => () => res.json()),
    );
  }
}
