export enum Language {
  French = 'fr',
  English = 'en',
  Spanish = 'es',
  German = 'de',
  Dutch = 'nl',
  Portuguese = 'pt',
  Italian = 'it',
}

export enum Country {
  France = 'FR',
  GreatBritain = 'GB',
  Spain = 'ES',
  Germany = 'DE',
  Belgium = 'BE',
  Portugal = 'PT',
  Italy = 'IT',
}

export const isoLanguage: Record<Language, string> = {
  [Language.French]: 'fr-FR',
  [Language.English]: 'en-GB',
  [Language.Spanish]: 'es-ES',
  [Language.German]: 'de-DE',
  [Language.Dutch]: 'nl-NL',
  [Language.Portuguese]: 'pt-BR',
  [Language.Italian]: 'it-IT',
};

export const languageCountry: Record<Language, Country> = {
  [Language.French]: Country.France,
  [Language.English]: Country.GreatBritain,
  [Language.Spanish]: Country.Spain,
  [Language.German]: Country.Germany,
  [Language.Dutch]: Country.Belgium,
  [Language.Portuguese]: Country.Portugal,
  [Language.Italian]: Country.Italy,
};
