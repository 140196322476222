import { Language } from '../core/lang/model';

/**
 * Parse cookies
 *
 * @returns {{}}
 */
export const getCookies = () =>
  document.cookie.split(';').reduce((cookies, item) => {
    const _cookies = cookies;
    const [name, value] = item.split('=');

    if (name && value) {
      _cookies[name.trim()] = value;
    }

    return _cookies;
  }, {});

export const getLangFromCookies = () => getCookies().PLAY_LANG || Language.French;
