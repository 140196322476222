import React, { useState } from 'react';
import { Pagination, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { SiteCard } from '../SiteCard';
import { Site } from '../model';

import 'swiper/swiper.scss';
import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/navigation/navigation.scss';

function Slider({ isLgUp }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const groupLength = isLgUp() ? 4 : 1;

  return (
    <Swiper
      slidesPerView="auto"
      spaceBetween={20}
      slidesPerGroup={groupLength}
      speed={400}
      navigation
      pagination={{
        clickable: true,
      }}
      watchSlidesProgress
      modules={[Pagination, Navigation]}
      className="mySwiper"
      onSlideChange={swiper => setActiveIndex(swiper.activeIndex)}>
      {Site.keys.map((key, i) => {
        const isGroupFull = activeIndex % groupLength === 0;
        const nextGroupPreviewIndex = isGroupFull ? activeIndex + groupLength : activeIndex - 1;
        const isStartNewGroup = i === nextGroupPreviewIndex;

        return (
          <SwiperSlide key={key}>
            <SiteCard siteKey={key} customClass={`redirectOnLp ${isStartNewGroup ? 'nextGroupPreview' : ''}`} />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
}

export default Slider;
