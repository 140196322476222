import $ from 'jquery';
import 'magnific-popup';
import i18next from 'i18next';
import { StripeElementLocale, StripePaymentElement, PaymentIntent, StripeError } from '@stripe/stripe-js';

import { pipe } from 'fp-ts/function';
import * as TE from 'fp-ts/TaskEither';
import * as T from 'fp-ts/Task';
import * as E from 'fp-ts/Either';

import { PaymentMethod, PaymentSourceStoreStrategy, RedirectError, StripePaymentMethod } from './model';
import { StripeService } from '../../core/stripe/service';
import { PaymentService } from '../payment/service';

let abortController = new AbortController();

const bindStoreStrategy = ($form: JQuery) => {
  // Handling modal
  const $saveCardCheckbox = $form.find('#SaveCardCheckbox');
  const $changeStrategyModal = $('#ChangePaymentStoreStrategyModal');
  const $simpleStoreForm = $form.find('#SimplePaymentStore');
  const $fullStoreForm = $form.find('#FullPaymentStore');

  const hideAndSetStrategy = (strategy: PaymentSourceStoreStrategy) => {
    $form.find(`input[name="strategy"][value="${strategy}"]`).prop('checked', true);
    $.magnificPopup.close();
  };

  $changeStrategyModal
    .find('button.keep-strategy')
    .on('click', () => hideAndSetStrategy(PaymentSourceStoreStrategy.RemoveOnceCompleted));

  $changeStrategyModal
    .find('button.none-strategy')
    .on('click', () => hideAndSetStrategy(PaymentSourceStoreStrategy.None));

  // Handling buttons behavior
  $saveCardCheckbox.on('change', () => {
    $simpleStoreForm.remove();
    $fullStoreForm.show();

    $.magnificPopup.open({
      items: {
        src: $changeStrategyModal,
      },
    });
  });
};

export const setPaymentMethod = (method: PaymentMethod) => $('input[name="paymentMethod"]').val(method);

const getSubmit = ($form: JQuery) => $form.find('button[type="submit"]');

const getError = ($form: JQuery) => $form.find('#payment-errors');
const hideError = ($form: JQuery) => getError($form).empty();

let isStripeFormOkay = false;

export const enablePaymentSubmit = ($form: JQuery) => {
  const $submit = getSubmit($form);
  hideError($form);
  $submit.removeClass('disabled');
  $submit.prop('disabled', false);
};

const disablePaymentSubmit = ($form: JQuery) => {
  const $submit = getSubmit($form);
  $submit.addClass('disabled');
  $submit.prop('disabled', true);
};

export const enableSubmitIfPossible = ($form: JQuery) => {
  if (isStripeFormOkay) enablePaymentSubmit($form);
  else disablePaymentSubmit($form);
};

function configurePaymentMethods($form: JQuery) {
  if ($form.length === 0) return;

  const getStripeConnectAccount = () => $<HTMLInputElement>('input[name="stripeConnectAccount"]').val();

  const getTotal = (): number => {
    const total = $<HTMLInputElement>('input[name="total"]').val();
    return Number(total);
  };
  const getCurrency = (): string | undefined => $<HTMLInputElement>('input[name="currency"]').val();

  const getLocale = (): StripeElementLocale | undefined => {
    const locale = $<HTMLInputElement>('input[name="locale"]').val();
    return locale ? (locale as StripeElementLocale) : undefined;
  };

  if (!window.Stripe) return;

  const stripeAccountService = StripeService.make(window.Stripe(window.STRIPE_API_KEY));

  const stripeConnectService = StripeService.make(
    window.Stripe(window.STRIPE_API_KEY, {
      stripeAccount: getStripeConnectAccount(),
    }),
  );

  const elements = stripeAccountService.createElements(getTotal(), getCurrency(), getLocale());

  // Mount Stripe component
  const payment: StripePaymentElement = elements.create('payment', {
    layout: {
      type: 'accordion',
      defaultCollapsed: false,
      radios: true,
      spacedAccordionItems: true,
    },
  });

  const $newPm = $form.find('#new-pm');
  const $existingPm = $form.find('#existing-pm');
  const $newCardForm = $form.find('#NewCard');
  const $monthlyPm = $form.find('#monthly-pm');

  const $submit = getSubmit($form);

  const getIsCurrentCardSelected = () => $existingPm.hasClass('active');

  const displayError = (error: string) => getError($form).html(`<p>${error}</p>`);

  if ($form.find('#card-element').length) {
    payment.mount('#card-element');
    payment.on('change', event => {
      if (event.complete) {
        isStripeFormOkay = true;
        enablePaymentSubmit($form);
      }
    });
  }

  const setStripeCard = (name: string, value: string) => {
    const $tokenInput = $form.find(`input[name="${name}"]`);
    if ($tokenInput && $tokenInput.length) {
      $tokenInput.val(value);
    } else {
      // Adding token as hidden input and submit
      const hiddenInput = document.createElement('input');
      hiddenInput.setAttribute('type', 'hidden');
      hiddenInput.setAttribute('name', name);
      hiddenInput.setAttribute('value', value);
      $form.append(hiddenInput);
    }
  };

  const getPaymentIntent = (methodId: string | undefined) => {
    const csrfToken = $<HTMLInputElement>('input[name="csrfToken"]').val();
    const gaClientId = $<HTMLInputElement>('#gaClientId').val();
    const creditsCount = $<HTMLInputElement>('input[name="creditsCount"]').val();
    const strategy = $<HTMLInputElement>('input[name="strategy"]:checked').val();
    const subscribeToNewsletter = $<HTMLInputElement>('input[name="subscribeToNewsletter"]:checked').val() === 'true';

    return PaymentService.getPaymentIntent(csrfToken, {
      methodId,
      creditsCount,
      gaClientId,
      strategy,
      subscribeToNewsletter,
    });
  };

  const confirmPayment = (
    intentSecret: string,
    methodId: string,
    methodType: string,
  ): TE.TaskEither<StripeError | Error, PaymentIntent> => {
    switch (methodType) {
      case StripePaymentMethod.Card:
        return stripeConnectService.confirmCardPayment(intentSecret, methodId);
      case StripePaymentMethod.Paypal:
        return stripeAccountService.confirmPayPalPayment(
          intentSecret,
          methodId,
          `${window.location.origin}/cart/payment/waiting`,
        );
      default:
        return TE.left(new Error('Méthode de paiement non supportée'));
    }
  };

  const submitElements = (): TE.TaskEither<StripeError | Error, string> =>
    pipe(
      () => elements.submit(),
      T.map(result =>
        result.selectedPaymentMethod
          ? E.right(result.selectedPaymentMethod)
          : E.left(result.error ?? new Error('StripeElements submit failed')),
      ),
    );

  const handlePayment = () => {
    if (getIsCurrentCardSelected()) {
      const existingCard = $existingPm.attr('data-id');
      return pipe(
        getPaymentIntent(existingCard),
        TE.flatMap(({ intent_secret: intentSecret, method_id: methodId }) =>
          confirmPayment(intentSecret, methodId, StripePaymentMethod.Card),
        ),
      );
    } else {
      return pipe(
        submitElements(),
        TE.flatMap(selectedPaymentMethod =>
          pipe(
            stripeAccountService.createPaymentMethod(payment),
            TE.flatMap(method => getPaymentIntent(method.id)),
            TE.flatMap(({ intent_secret: intentSecret, method_id: methodId }) =>
              confirmPayment(intentSecret, methodId, selectedPaymentMethod),
            ),
          ),
        ),
      );
    }
  };

  const pay = () =>
    pipe(
      handlePayment(),
      TE.flatMapIO(
        intent => () =>
          document.location.replace(`/cart/payment/waiting?payment_intent_client_secret=${intent.client_secret}`),
      ),
    );

  const confirm = () => {
    $form.attr('method', 'POST');
    $form.attr('action', '/cart/confirm');
    $form.submit();
  };

  // Handling submit
  $submit.on('click', event => {
    event.preventDefault();
    const acceptCGU = $('input[name="acceptCGU"]:checked').val() === 'true';
    if (!acceptCGU) {
      displayError(i18next.t('error_accept_cgu'));
      return;
    }

    hideError($form);

    $submit.addClass('loading');
    $submit.prop('disabled', true);

    // active should be checked differently maybe
    const method = $<HTMLInputElement>('input[name="paymentMethod"]').val();
    switch (method) {
      case PaymentMethod.Credits:
      case PaymentMethod.Monthly:
        confirm();
        break;
      default:
        pipe(
          pay(),
          TE.orElseFirstIOK(err => () => {
            if (err instanceof RedirectError) {
              document.location.replace(err.newLocation);
            } else {
              $submit.removeClass('loading');
              $submit.prop('disabled', false);
              displayError(err.message ?? err.toString());
            }
          }),
        )();
        break;
    }
  });

  const enablePayment = () => {
    const token = $existingPm.attr('data-id');
    if (token && token.length) {
      // Adding stripe id input
      setStripeCard('stripeId', token);
      enablePaymentSubmit($form);

      // Clearing Stripe element
      payment.clear();
    }
    if ($monthlyPm && $monthlyPm.hasClass('active')) {
      enablePaymentSubmit($form);
    }
  };

  enablePayment();

  // Handling change
  $newPm.on('click', event => {
    event.stopPropagation();

    $existingPm.removeClass('active');
    $newPm.addClass('active');
    $newCardForm.show();

    disablePaymentSubmit($form);
  });

  // Handling select
  $existingPm.on('click', event => {
    event.stopPropagation();

    if (!getIsCurrentCardSelected()) {
      $existingPm.addClass('active');
      $newPm.removeClass('active');
      $newCardForm.hide();
      enablePayment();
    }
  });

  bindStoreStrategy($form);

  abortController.signal.addEventListener(
    'abort',
    () => {
      $submit.off('click');
      $newPm.off('click');
      $existingPm.off('click');
      payment.off('change');
      payment.clear();
      payment.unmount();
    },
    { once: true },
  );
}

export function bindPaymentMethods($form: JQuery) {
  if (window.Stripe) configurePaymentMethods($form);
  else
    document.querySelector('#stripe-js')?.addEventListener('load', () => {
      configurePaymentMethods($form);
    });
}

export function reloadPaymentMethods() {
  // reload for apple/google amount
  const $form = $('#FinishForm');
  abortController.abort();
  abortController = new AbortController();
  bindPaymentMethods($form);
}
