import {
  Appearance,
  PaymentIntent,
  PaymentMethod,
  Stripe,
  StripeElementLocale,
  StripeElements,
  StripeError,
  StripePaymentElement,
} from '@stripe/stripe-js';
import { pipe } from 'fp-ts/function';
import * as T from 'fp-ts/Task';
import * as TE from 'fp-ts/TaskEither';
import * as E from 'fp-ts/Either';

const defaultAppearance: Appearance = {
  variables: {
    borderRadius: '20px',
    colorPrimary: '#1437b9',
    colorPrimaryText: '#1437b9',
    colorSuccess: '#04cab6',
    colorSuccessText: '#04cab6',
    colorDanger: '#ff436c',
    colorDangerText: '#ff436c',
  },
  rules: {
    '.Label': {
      margin: '0 14px 6px',
      fontWeight: '600',
      fontSize: '11px',
      letterSpacing: '1px',
      color: '#001875',
      textTransform: 'uppercase',
    },
    '.Input': {
      fontSize: '14px',
      color: '#050505',
      borderColor: '#b9c9d6',
    },
    '.Input::placeholder': {
      color: '#5d778a',
    },
  },
};

export class StripeService {
  stripe: Stripe;

  private constructor(stripe: Stripe) {
    this.stripe = stripe;
  }

  static make(stripe: Stripe) {
    return new StripeService(stripe);
  }

  createElements(total: number, currency: string | undefined, locale: StripeElementLocale | undefined): StripeElements {
    return this.stripe.elements({
      mode: 'payment',
      amount: Math.round(total * 100),
      currency,
      captureMethod: 'automatic',
      locale,
      paymentMethodCreation: 'manual',
      appearance: defaultAppearance,
    });
  }

  createPaymentMethod(payment: StripePaymentElement): TE.TaskEither<StripeError, PaymentMethod> {
    return pipe(
      () => this.stripe.createPaymentMethod({ element: payment }),
      T.map(({ error, paymentMethod }) => (error ? E.left(error) : E.right(paymentMethod))),
    );
  }

  confirmCardPayment(intentSecret: string, methodId: string): TE.TaskEither<StripeError, PaymentIntent> {
    return pipe(
      () => this.stripe.confirmCardPayment(intentSecret, { payment_method: methodId }),
      T.map(({ error, paymentIntent }) => (error ? E.left(error) : E.right(paymentIntent))),
    );
  }

  confirmPayPalPayment(
    intentSecret: string,
    methodId: string,
    returnUrl: string,
  ): TE.TaskEither<StripeError, PaymentIntent> {
    return pipe(
      () => this.stripe.confirmPayPalPayment(intentSecret, { payment_method: methodId, return_url: returnUrl }),
      T.map(({ error, paymentIntent }) => (error ? E.left(error) : E.right(paymentIntent))),
    );
  }
}
