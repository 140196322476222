import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@mantine/core';
import { IconArrowRight } from '@tabler/icons-react';
import { Site } from './model';
import RatingStars from '../reviews/components/RatingStars';
import { ReactComponent as AirportIcon } from '../../static/images/icons/airport.svg';
import { ReactComponent as StationIcon } from '../../static/images/icons/station.svg';

const typeIcon: Record<Site.Type, JSX.Element> = {
  [Site.Type.Airport]: <AirportIcon />,
  [Site.Type.Station]: <StationIcon />,
};

const defaultRating = 4.6;

interface SiteCardProps {
  siteKey: Site.Key;
  customClass?: string;
  withLink?: boolean;
}

export const SiteCard: FC<SiteCardProps> = ({ siteKey: key, customClass = '', withLink = true }) => {
  const { t, i18n } = useTranslation();

  const [type, label, image, slug] = [Site.type[key], Site.label[key], Site.image[key], Site.slug[key]];

  const url = `/${i18n.language}/landing/${slug}`;

  const content = (
    <>
      <img width={300} height={450} loading="lazy" className="backgroundImage" src={image} alt={`${label} ${type}`} />
      <div className="card">
        <div className={`iconContainer ${type}`}>{typeIcon[type]}</div>
        <div className="itemsContainer">
          <div className="ratingContainer">
            <p className="rateAmount">{`${defaultRating}/5`}</p>
            <div className="starsContainer">
              <RatingStars rating={defaultRating} />
            </div>
          </div>
          <p className="title">
            <Trans
              i18nKey={`customer_reviews_lp_sites_${type}`}
              values={{
                siteName: label,
              }}
            />
          </p>
          {withLink ? (
            <Button
              radius="xl"
              color="primary"
              w="fit-content"
              fz={10}
              fw={700}
              rightIcon={<IconArrowRight color="white" size={18} />}
              styles={{ label: { color: 'white !important' }, rightIcon: { marginLeft: 7 } }}>
              {t('book')}
            </Button>
          ) : null}
        </div>
      </div>
    </>
  );

  return (
    <a className={`siteCard ${customClass}`} href={withLink ? url : undefined}>
      {content}
    </a>
  );
};
