import React, { FC } from 'react';
import { ReactComponent as Star } from '../../../static/images/icons/star.svg';

interface RatingStarsProps {
  rating: number;
}

const RatingStars: FC<RatingStarsProps> = ({ rating }) => {
  const roundedRating = Math.round(rating);
  return (
    <div className="starsContainer">
      {[...Array(5)].map((_, i) => {
        const starIsUnfilled = i + 1 > roundedRating;
        return <Star className={`star ${starIsUnfilled ? 'unfilled' : ''}`} key={i} />;
      })}
    </div>
  );
};

export default RatingStars;
