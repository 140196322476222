import { OrderedArray } from '../../utils/effect';

import bdxMerignacImg from '../../static/images/sites/bdx-merignac.webp';
import bdxStJeanImg from '../../static/images/sites/bdx-stJean.webp';
import beauvaisImg from '../../static/images/sites/beauvais-airport.webp';
import bslAirportImg from '../../static/images/sites/bsl-airport.webp';
import lyonAirportImg from '../../static/images/sites/lyon-stEx.webp';
import lyonStationImg from '../../static/images/sites/lyon-stEx-station.webp';
import mrsProvenceImg from '../../static/images/sites/mrs-provence.webp';
import aixProvenceStationImg from '../../static/images/sites/aix-provence-station.webp';
import nantesImg from '../../static/images/sites/nantes.webp';
import niceImg from '../../static/images/sites/nice.webp';
import parisCdgImg from '../../static/images/sites/paris-cdg.webp';
import parisOrlyImg from '../../static/images/sites/paris-orly.webp';
import tlsBlagnacImg from '../../static/images/sites/toulouse-blagnac.webp';

export namespace Site {
  export enum Key {
    AIX = 'AIX',
    BOD = 'BOD',
    BODG = 'BODG',
    BSL = 'BSL',
    BVA = 'BVA',
    CDG = 'CDG',
    ORY = 'ORY',
    MRS = 'MRS',
    NCE = 'NCE',
    NTE = 'NTE',
    LYS = 'LYS',
    LYSG = 'LYSG',
    TLS = 'TLS',
  }

  export enum Type {
    Airport = 'airport',
    Station = 'station',
  }

  export const label: Record<Key, string> = {
    [Key.AIX]: 'Aix-en-Provence',
    [Key.BOD]: 'Bordeaux Mérignac',
    [Key.BODG]: 'Bordeaux Saint-Jean',
    [Key.BSL]: 'Bâle-Mulhouse',
    [Key.BVA]: 'Paris Beauvais',
    [Key.CDG]: 'Paris Charles de Gaulle',
    [Key.ORY]: 'Paris Orly',
    [Key.MRS]: 'Marseille Provence',
    [Key.NCE]: 'Nice',
    [Key.NTE]: 'Nantes',
    [Key.LYS]: 'Lyon Saint Exupéry',
    [Key.LYSG]: 'Lyon Saint Exupéry',
    [Key.TLS]: 'Toulouse Blagnac',
  };

  export const slug: Record<Key, string> = {
    [Key.AIX]: 'aix-station',
    [Key.BOD]: 'bordeaux-airport',
    [Key.BODG]: 'bordeaux-station',
    [Key.BSL]: 'bale-mulhouse-station',
    [Key.BVA]: 'beauvais-airport',
    [Key.CDG]: 'roissy-airport',
    [Key.ORY]: 'orly-airport',
    [Key.MRS]: 'marseille-airport',
    [Key.NCE]: 'nice-airport',
    [Key.NTE]: 'nantes-airport',
    [Key.LYS]: 'lyon-airport',
    [Key.LYSG]: 'lyon-station',
    [Key.TLS]: 'toulouse-airport',
  };

  export const type: Record<Key, Type> = {
    [Key.AIX]: Type.Station,
    [Key.BOD]: Type.Airport,
    [Key.BODG]: Type.Station,
    [Key.BSL]: Type.Airport,
    [Key.BVA]: Type.Airport,
    [Key.CDG]: Type.Airport,
    [Key.ORY]: Type.Airport,
    [Key.MRS]: Type.Airport,
    [Key.NCE]: Type.Airport,
    [Key.NTE]: Type.Airport,
    [Key.LYS]: Type.Airport,
    [Key.LYSG]: Type.Station,
    [Key.TLS]: Type.Airport,
  };

  export const image: Record<Key, string> = {
    [Key.AIX]: aixProvenceStationImg,
    [Key.BOD]: bdxMerignacImg,
    [Key.BODG]: bdxStJeanImg,
    [Key.BSL]: bslAirportImg,
    [Key.BVA]: beauvaisImg,
    [Key.CDG]: parisCdgImg,
    [Key.ORY]: parisOrlyImg,
    [Key.MRS]: mrsProvenceImg,
    [Key.NCE]: niceImg,
    [Key.NTE]: nantesImg,
    [Key.LYS]: lyonAirportImg,
    [Key.LYSG]: lyonStationImg,
    [Key.TLS]: tlsBlagnacImg,
  };

  export const keys = OrderedArray.fromKeys(
    Site.Key,
    Site.Key.BOD,
    Site.Key.BODG,
    Site.Key.BSL,
    Site.Key.ORY,
    Site.Key.CDG,
    Site.Key.LYS,
    Site.Key.LYSG,
    Site.Key.TLS,
    Site.Key.MRS,
    Site.Key.AIX,
    Site.Key.NCE,
    Site.Key.NTE,
    Site.Key.BVA,
  );
}

export interface Site {
  key: Site.Key;
  slug: string;
  label: string;
  type: Site.Type;
  image: string;
}
