/* @flow */

import React, { useEffect, useRef } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from '@bluevalet/common-ui';

import { isSafari } from '../../../utils/navigator';
import { calcDiscount, displayDate, isDisplayablePromotion } from '../utils';
import type { FormContent } from './Form';
import type { Estimation } from '../types';
import { scrollTo } from '../../../plugins/smooth-scroll';
import { pushValidateEstimationEvents } from '../tagging';
import { Language } from '../../../core/lang/model';

type Props = {
  t: (key: string) => string,
  departure: FormContent,
  arrival: FormContent,
  estimation?: Estimation,
};

const PriceSumUp = ({
  price,
  daysDuration,
  language = Language.French,
  isCrossedOutPrice,
  isDiscountPrice,
  discount,
}) => {
  const isNumber = value => !Number.isNaN(Number(value));

  if (isNumber(daysDuration) && isNumber(price)) {
    const dailyPrice = price / daysDuration;
    const formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    const dailyPriceFormatted = new Intl.NumberFormat(language, formatOptions).format(dailyPrice);
    const totalPriceFormatted = new Intl.NumberFormat(language, formatOptions).format(price);

    const classNames = (isCrossedOutPrice && 'crossedOut') || (isDiscountPrice && 'discount');

    return (
      <div className={`priceSumUp ${classNames || ''}`}>
        {isDiscountPrice && (
          <p className="discountLabel">
            <Trans i18nKey="booking-sum_up-promo_label" values={{ discount }} />
          </p>
        )}
        <p data-cy="estimation-total" className="totalPrice">
          {`${totalPriceFormatted} €`}
        </p>
        <p className="pricePerDay" data-cy="estimation-price-per-day">
          <Trans
            i18nKey="booking-sum_up-price_per_day"
            values={{
              pricePerDay: dailyPriceFormatted,
            }}
          />
        </p>
      </div>
    );
  }
  return null;
};

const EstimationSummary = ({ t, departure, arrival, estimation, i18n }: Props) => {
  const resultForm = useRef(null);
  const { durationInDays, total, totalWithoutPromotion } = estimation || {};

  useEffect(() => {
    if (total && durationInDays) {
      const estimationElement = document.querySelector('#estimationSummaryForm');
      if (typeof estimationElement.scrollIntoView === 'function' && !isSafari) {
        estimationElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        scrollTo(resultForm.current);
      }
    }
  }, [total, durationInDays]);

  const handleSubmit = () => {
    pushValidateEstimationEvents(estimation);
  };

  if (total && durationInDays) {
    const departureDate = displayDate(departure.date);
    const arrivalDate = displayDate(arrival.date);
    return (
      <form method="POST" id="estimationSummaryForm" action="/orders/book" onSubmit={handleSubmit}>
        <input type="hidden" name="startSite" value={departure.site} />
        <input type="hidden" name="startDate" value={departureDate} />
        <input type="hidden" name="startHour" value={departure.hour} />
        <input type="hidden" name="endSite" value={arrival.site} />
        <input type="hidden" name="endDate" value={arrivalDate} />
        <input type="hidden" name="endHour" value={arrival.hour} />

        <div ref={resultForm} className={`result show ${totalWithoutPromotion ? 'withPromo' : ''}`}>
          <p className="servicesIncludedSummary" data-cy="estimation-summary">
            <Trans
              i18nKey={
                estimation?.durationInDays > 1
                  ? 'booking-sum_up-services_included'
                  : 'booking-sum_up-services_included_one'
              }
              values={{ totalDays: estimation?.durationInDays }}
            />
          </p>
          <div className="priceSumUpContainer">
            {isDisplayablePromotion(totalWithoutPromotion, total) && (
              <PriceSumUp
                isCrossedOutPrice
                daysDuration={durationInDays}
                price={totalWithoutPromotion}
                language={i18n?.language}
              />
            )}
            <PriceSumUp
              isDiscountPrice={isDisplayablePromotion(totalWithoutPromotion, total)}
              discount={calcDiscount(totalWithoutPromotion, total)}
              daysDuration={durationInDays}
              price={total}
              language={i18n?.language}
            />
          </div>
          <div className="simulatePrice">
            <Button data-cy="estimation-submit" type="submit" primary label={t('booking-sum_up-cta')} />
          </div>
        </div>
      </form>
    );
  }

  return null;
};

export default withTranslation()(EstimationSummary);
