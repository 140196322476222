/* @flow */

import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import $ from 'jquery';
import Moment from 'moment';
import { extendMoment } from 'moment-range';

import CalendarIcon from '@bluevalet/react-icons/calendar';
import { Input } from '@bluevalet/common-ui';

import { hasDateChanged, isErrorField } from '../utils';
import { isoLanguage, Language } from '../../../core/lang/model';

const moment = extendMoment(Moment);

const getLang = (lang: string) => isoLanguage[lang] ?? isoLanguage[Language.English];

const pickerOptions = i18n => ({
  language: getLang(i18n.language),
  format: 'dd/mm/yyyy',
  startDate: new Date(),
  weekStart: 1,
  autoHide: true,
  startView: 0,
  endView: 0,
});

type DatePickerHTMLEvent = {
  date: Date,
};

type Props = {
  i18n: any,
  t: (key: string) => string,
  prefix: String,
  date?: Date,
  label: string,
  placeholder?: string,
  forceValidation?: boolean,
  onDateChange: (value: Date) => void,
};

class DayPicker extends PureComponent {
  static props: Props;

  constructor(props) {
    super(props);
    this.refName = `${props.prefix}Input`;
    this[this.refName] = React.createRef();
  }

  componentDidMount() {
    const { date, departure, arrival, i18n, prefix } = this.props;
    const $picker = $(this[this.refName].current);

    $picker.datepicker({
      ...pickerOptions(i18n),
      pick: this.onDateChange,
    });

    if (date) {
      $picker.datepicker('setDate', date, true);

      // Setting value manually to avoid event triggering
      const value = $picker.datepicker('formatDate', date);
      $picker.datepicker('setValue', value);
    }

    if (prefix === 'start' && arrival.date) {
      $picker.datepicker('setEndDate', arrival.date);
    }

    if (prefix === 'end' && departure.date) {
      $picker.datepicker('setStartDate', departure.date);
      $picker.datepicker('setViewDate', departure.date);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { prefix, departure, arrival } = this.props;
    const $picker = $(this[this.refName].current);
    const departureDate = departure.date;
    const arrivalDate = arrival.date;

    if (prefix === 'start' && hasDateChanged(arrivalDate, prevProps.arrival.date)) {
      $picker.datepicker('setEndDate', arrivalDate);
    }

    if (prefix === 'end' && hasDateChanged(departureDate, prevProps.departure.date)) {
      $picker.datepicker('setStartDate', departureDate);

      if (!prevProps.date) {
        $picker.datepicker('setViewDate', departureDate);
      }
    }
  }

  onDateChange = (event: DatePickerHTMLEvent) => {
    const { onDateChange } = this.props;
    onDateChange(new Date(event.date));
  };

  onChangeDate = ({ target: { value } }) => {
    const {
      departure: { date },
      onDateChange,
    } = this.props;
    const departureDate = moment(date);
    const dateValue = moment(value, 'DD/MM/YYYY', true);
    if (dateValue.isValid() && dateValue.isSameOrAfter(departureDate, 'day')) {
      onDateChange(new Date(dateValue));
    }
  };

  render() {
    const { t, prefix, label, placeholder, date, trip, tripDetail, fastBooking, forceValidation } = this.props;

    // Gestion des erreurs
    const hasErrors = isErrorField(forceValidation, date);

    const inputProps = {
      success: false,
      error: hasErrors,
      message: hasErrors ? t('error-required') : '',
    };

    // Si on est en mode "full booking"
    // et qu'un voyage a été sélectionné,
    // alors on va vérifier si les infos renseignées par le client sont correctes
    if (!fastBooking) {
      if (trip.isValid && !trip.isForced) {
        const selectedDate = moment(date);
        const tripTime = prefix === 'start' ? tripDetail.departureTime : tripDetail.arrivalTime;
        const tripDate = moment(tripTime, 'DD/MM/YYYY HH:mm');

        // On prévient l'utilisateur s'il change la date
        // après avoir choisi un voyage
        if (selectedDate.isSame(tripDate, 'day')) {
          inputProps.success = true;
        } else {
          inputProps.error = true;
        }
      } else if (trip.isForced) {
        inputProps.success = true;
        inputProps.error = false;
        inputProps.message = '';
      }
    }

    // On désactive le picker si la date est correcte
    const $picker = $(this[this.refName].current);
    $picker.prop('disabled', inputProps.success);

    const dateName = `${prefix}-date`;

    return (
      <div className="DayPicker">
        <Input
          ref={this[this.refName]}
          data-cy={dateName}
          id={dateName}
          name="date"
          label={label}
          type="text"
          placeholder={placeholder || t('date')}
          autoComplete="off"
          onChange={this.onChangeDate}
          icon={<CalendarIcon />}
          inputMode="none"
          {...inputProps}
        />
      </div>
    );
  }
}

const mstp = ({ booking: { departure, arrival }, trip }, props) => ({
  departure,
  arrival,
  trip: trip[props.prefix],
});

export default connect(mstp)(withTranslation()(DayPicker));
