export type OrderedArray<T> = T & { readonly _URI: 'OrderedArray' };

export function fromKeys<E extends string | number, Enum extends Record<string, E>, T extends ReadonlyArray<E>>(
  enumType: Enum,
  ...t: T
): HasDuplicate<T> extends false
  ? T[number] extends Enum[keyof Enum]
    ? Enum[keyof Enum] extends T[number]
      ? OrderedArray<T>
      : never
    : never
  : never {
  return t as any;
}

type HasDuplicate<TUPLE> = TUPLE extends [infer L, ...infer R] ? (L extends R[number] ? true : HasDuplicate<R>) : false;
