import React, { FC, useEffect, useMemo, useState } from 'react';
import { Box, Button, CopyButton, Paper, Stack, Text, Tooltip } from '@mantine/core';
import { Review } from '@modules/reviews/model';
import { IconCheck, IconCopy } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

interface WithVoucherProps {
  voucher: string;
}

export const ShareReviewModalContentWithVoucher: FC<WithVoucherProps> = ({ voucher }) => {
  const { t } = useTranslation();

  return (
    <Stack ta="center" align="center" spacing="xl">
      <Text color="blue.7" fz="xl" fw={700}>
        {t('mail-review-voucher-shown_title')}
      </Text>
      <Box>
        <Text color="blue.6" fw={800}>
          {t('mail-review-voucher-shown_subtitle')}
        </Text>
        <Text maw={410} fz="sm" fw={500} style={{ whiteSpace: 'pre-line' }}>
          {t('mail-review-voucher-shown_comment')}
        </Text>
      </Box>

      <Paper shadow="xl" px="xl" py="xs" sx={({ colors }) => ({ border: `2px solid ${colors.blue[6]}` })}>
        <Text c="blue.6" fw={600}>
          {voucher}
        </Text>
      </Paper>

      <Text fs="italic" fz="xs" maw={300}>
        {t('mail-review-voucher-shown_warning')}
      </Text>

      <CopyButton value={voucher} timeout={2000}>
        {({ copied, copy }) => (
          <Button
            size="md"
            color="primary"
            onClick={copy}
            radius="xl"
            tt="uppercase"
            styles={{ label: { color: 'white !important' } }}>
            {copied ? t('mail-review_copied') : t('mail-review-voucher-shown_copy')}
          </Button>
        )}
      </CopyButton>
    </Stack>
  );
};

interface WithoutVoucherProps {
  platform: Review.Platform;
  onReviewed(): void;
}

export const ShareReviewModalContentWithoutVoucher: FC<WithoutVoucherProps> = ({ platform, onReviewed }) => {
  const { t } = useTranslation();
  const [copyValue, setCopyValue] = useState('');

  const commentPreview = useMemo(
    () => (copyValue.length > 50 ? `${copyValue.substring(0, 50)}...` : copyValue),
    [copyValue],
  );

  useEffect(() => {
    const textArea = document.getElementById('description') as HTMLTextAreaElement | null;
    if (textArea) setCopyValue(textArea.value);
  }, []);

  return (
    <Stack ta="center" align="center" spacing="xl">
      <Text color="blue.7" fz="xl" fw={700} tt="uppercase">
        {t('mail-review_title')}
      </Text>
      <Text maw={460} style={{ whiteSpace: 'pre-line' }}>
        {t('mail-review_content', { platform: Review.platformLabel[platform] })}
      </Text>
      {copyValue.trim().length > 0 && (
        <CopyButton value={copyValue} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={commentPreview} multiline>
              <Button size="md" variant="outline" color="gray" onClick={copy} radius="xl" tt="uppercase">
                {copied ? <IconCheck /> : <IconCopy />}&nbsp;
                {copied ? t('mail-review_copied') : t('mail-review_copy_comment')}
              </Button>
            </Tooltip>
          )}
        </CopyButton>
      )}

      <Button
        component="a"
        onClick={onReviewed}
        href={Review.platformUrl[platform]}
        target="_blank"
        rel="noopener noreferrer"
        size="md"
        color="primary"
        radius="xl"
        tt="uppercase"
        styles={{ label: { color: 'white !important' } }}>
        {t('mail-review_share', { platform: Review.platformLabel[platform] })}
      </Button>

      <Paper shadow="xl" p="xs" sx={({ colors }) => ({ border: `2px solid ${colors.blue[6]}` })} radius={8} maw={500}>
        <Text color="blue.6" fw={800}>
          {t('mail-review-voucher-hidden_title')}
        </Text>
        <Text>{t('mail-review-voucher-hidden_comment')}</Text>
      </Paper>
    </Stack>
  );
};
