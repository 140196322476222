import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { isLgUp } from '@utils/navigator';
import Slider from './components/Slider';
import i18n from '../../i18n';
import { MantineTheme } from '../../styles/mantine';

export default function (rootName) {
  const sitesSliderRoot = document.getElementById(rootName);

  if (sitesSliderRoot) {
    ReactDOM.render(
      <React.Suspense fallback={<div />}>
        <I18nextProvider i18n={i18n}>
          <MantineTheme>
            <Slider isLgUp={isLgUp} />
          </MantineTheme>
        </I18nextProvider>
      </React.Suspense>,
      sitesSliderRoot,
    );
  }
}
